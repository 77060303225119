.form-control[type=file] {
    overflow:hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor:pointer
}

.form-control:focus {
    /* @apply shadow-md; */
    box-shadow:0 0 0 1px #2563eb
}

.form-control::file-selector-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    margin-inline-end: .75rem;
    color: #212529;
    /* background-color: #e9ecef; */
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 0px;
    border-radius: 0;
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color:#dde0e3
}

.form-control::-webkit-file-upload-button {
    @apply bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-700 text-blue-500;
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color:#dde0e3
}